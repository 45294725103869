import React, { useState } from "react"
import QuoteForm from "../components/quoteForm"
import QuoteRange from "../components/quoteRange"

export default function Page() {
    const [debug, setDebug] = useState(false);
    const [range, setRange] = useState(false);
    const [activeSection, setActiveSection] = useState(1);
    const [budgetStart, setBudgetStart] = useState(25000);
    const [budgetEnd, setBudgetEnd] = useState(50000);
    const [budgetMax, setBudgetMax] = useState(100000);

    return (
        <div className={`mt-16 quote-page ${debug ? 'quote-debug-on' : 'quote-debug-off'}`}>
            <div className="container quote-container flex flex-wrap flex-col items-center">
                <h1 className="mb-2">Get a <span>Quote</span></h1>
                {activeSection === 1 &&
                    <div className="mb-12">
                        <h3 className="text-center mb-8 font-normal">for Website Design & Development.</h3>
                        <p className="text-center">Answer the questions below and we will <br className="hidden md:inline"/>respond with a quote as soon as possible.</p>
                        <p className="text-center">Feel free to <a href="tel:+27861377704">give us a call</a> if you need clarity on any of <br className="hidden md:inline"/>these questions, or <a href="mailto:info@artificial.studio?subject=Quoting session">set up a session</a> with us to <br className="hidden md:inline"/>complete the form together.</p>
                    </div>
                }

                <ul className={`steps step-${activeSection}`}>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>

                <QuoteForm
                    debug={debug}
                    activeSection={activeSection}
                    setActiveSection={setActiveSection}
                    setBudgetStart={setBudgetStart}
                    setBudgetEnd={setBudgetEnd}
                    setBudgetMax={setBudgetMax}
                    budgetStart={budgetStart}
                    budgetEnd={budgetEnd}
                    budgetMax={budgetMax}
                />
            </div>
            {range &&
                <QuoteRange
                    debug={debug}
                    budgetStart={budgetStart}
                    budgetEnd={budgetEnd}
                    budgetMax={budgetMax}
                />
            }
        </div>
    )
}
